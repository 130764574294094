import React from 'react'
import {Row, Col, Switch, Layout, Form, Input, Typography, Divider, Button} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import IFrameComponent from "../Components/IFrameComponent";
import image from '../Common/Images/frontpage.png'
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import UserService from '../Common/_services/user.service'
import SendNotification from "../Common/Utils/SendNotification";

const {Content} = Layout
const {TextArea} = Input
const {Title} = Typography

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const AddTemplate = () => {

  const onFinish = values => {
    //console.log('Received values of form: ', values);
    UserService.addTemplate(values.name, values.is_marketing, values.variables, values.categories, values.dynamic_template_id, values.from_email, values.from_name, values.reply_to_email)
      .then(r => {
        if(r.template) {
          SendNotification('', 'Template Added')
        }
        //console.log(r)
      })
  };

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>

        <Col md={{span: 24}} lg={{span: 12}} >
          <ContentWrapper>

            <Title>
              Add Template
            </Title>

            <Divider />

            <Form
              size="large"
              className="login-form"
              //{...formItemLayout}
              onFinish={onFinish}
              initialValues={[{
                is_marketing: false
              }]}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please Input template name'
                  }
                ]}
              >
                <Input
                  placeholder="Template Name"
                />
              </Form.Item>

              <Form.Item
                name="dynamic_template_id"
                rules={[
                  {
                    required: true,
                    message: 'Please Input sendgrid dynamic template id'
                  }
                ]}
              >
                <Input
                  placeholder="Sendgrid Dynamic Template Id"
                />
              </Form.Item>

              <Form.Item
                name="from_email"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Email to send mail from'
                  }
                ]}
              >
                <Input
                  placeholder="From Email"
                />
              </Form.Item>

              <Form.Item
                name="reply_to_email"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Email for reply'
                  }
                ]}
              >
                <Input
                  placeholder="Reply Email"
                />
              </Form.Item>

              <Form.Item
                name="from_name"
                rules={[
                  {
                    required: true,
                    message: 'Please Input Name of From Email'
                  }
                ]}
              >
                <Input
                  placeholder="From Name"
                />
              </Form.Item>

              <Form.Item
                name="is_marketing"
                label="Is Marketing Email"
              >
                <Switch />
              </Form.Item>

              <Form.List name="variables">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Form.Item
                          //{...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                          //label={index === 0 ? 'Passengers' : ''}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input variable's name or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input placeholder="Enter Variable name" style={{ width: '80%' }} />
                          </Form.Item>
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Form.Item>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          style={{ width: '100%' }}
                        >
                          <PlusOutlined /> Add Variale
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>

              <Form.List name="categories">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Form.Item
                          //{...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                          //label={index === 0 ? 'Passengers' : ''}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input category's name or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input placeholder="Enter Category name" style={{ width: '80%' }} />
                          </Form.Item>
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Form.Item>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          style={{ width: '100%' }}
                        >
                          <PlusOutlined /> Add Category
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </ContentWrapper>
        </Col>

        {/*<Col md={{span: 24}} lg={{span: 12}}>
          <ContentWrapper marginLess>
            <Title style={{fontWeight: 'medium'}}>
              Here's what you can do with ProfileMate!
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            <IFrameComponent url={"https://www.youtube.com/embed/tFNWHLEIiJ0"} />
          </ContentWrapper>
        </Col>*/}

        {/*<Col md={{span: 24}} lg={{span: 12}} >
          <div>
            <img
              width="100%"
              src={image}
              alt={'image'}
            />
            <Button onClick={()=>setKey(2)} className="dark-button" type="primary" block size={"large"}>
              Download
            </Button>
          </div>
        </Col>*/}
      </Row>
    </PlainContentWrapper>
  )
}

export default AddTemplate