import React, {useState} from "react";
import ContentWrapper from "../Components/ContentWrapper";
import {Button, Row, Col} from "antd";
import AddUser from "./AddUser";
import ShowUsers from "./ShowUsers";
import PlainContentWrapper from "../Components/PlainContentWrapper";

const UsersBoard = () => {
  const [tab, setTab] = useState(0)

  function components() {
    switch (tab) {
      case 0: return TabChange()
      case 1: return <ShowUsers goBack={()=>setTab(0)} />
      case 2: return <AddUser goBack={()=>setTab(0)} />
      default: return TabChange()
    }
  }

  const TabChange = () => {
    return(
      <ContentWrapper>
        <Row align='center' gutter={24} >
          <Col>
            <Button size='large' type='primary' onClick={()=>setTab(1)}>
              Show Users
            </Button>
            <br />
            <br />
            <Button type='primary' size='large' onClick={()=>setTab(2)}>
              Add User
            </Button>
          </Col>
        </Row>
      </ContentWrapper>
    )
  }

  return(
    <PlainContentWrapper>
      {components()}
    </PlainContentWrapper>
  )
}

export default UsersBoard