import React from "react";
import {Divider, Button, Form, Input, Typography} from "antd";
import UserService from '../Common/_services/user.service'
import SendNotification from "../Common/Utils/SendNotification";
import ContentWrapper from "../Components/ContentWrapper";

const {Title} = Typography

const AddUser = ({goBack}) => {

  const Finish = (values) => {
    UserService.addUser(values.username, values.password)
      .then(r => {
        if(r.id) {
          SendNotification('', 'User Added')
          goBack()
        }
      })
  }

  return(
    <ContentWrapper>
      <Title>
        Add User
      </Title>
      <div>
        <Button className="float-right" onClick={goBack}>
          Go Back
        </Button>
      </div>
      <Divider />

      <Form
        size='large'
        onFinish={Finish}
      >
        <Form.Item
          name='username'
          rules={[{
            required: true,
            message: 'Input User Name'
          }]}
        >
          <Input placeholder='Input User Name' />
        </Form.Item>

        <Form.Item
          name='password'
          rules={[{
            required: true,
            message: 'Input password'
          }]}
        >
          <Input placeholder='Input password for user' />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType='submit' size="large">
            Add
          </Button>
        </Form.Item>
      </Form>
    </ContentWrapper>
  )
}

export default AddUser