import React, {useEffect, useState} from "react";
import {Layout, Menu, Button} from "antd";
import logo from '../Common/Images/logo-full.png'
import {
  UserOutlined,
  HomeOutlined,
  PlusOutlined,
  EyeOutlined, MailOutlined
} from '@ant-design/icons';
import HomePage from "./HomePage";
import isMobile from "is-mobile";
import AuthService from '../Common/_services/auth.service'
import AddTemplate from "./AddTemplate";
import ShowTemplates from "./ShowTemplates";
import UsersBoard from "./UsersBoard";

const {Header, Sider, Content} = Layout

const MenuItems = [
  {
    title: 'Home',
    icon: <HomeOutlined />
  },
  {
    title: 'Add Template',
    icon: <PlusOutlined />
  },
  {
    title: 'Show Templates',
    icon: <EyeOutlined />
  },
  {
    title: 'Users Board',
    icon: <UserOutlined />
  }
]

const Dashboard = ({props}) => {
  const [collapsed, toggleCollapsed] = useState(false)
  const [key, setKey] = useState(1)
  const [user, setUser] = useState({username: ''})

  function toggle() {
    toggleCollapsed(!collapsed);
  }

  function toggleOnMobile() {
    if(isMobile()) {
      toggleCollapsed(true);
    }
  }

  function component() {
    switch (key) {
      case 1: return <HomePage username={user.username} />
      case 2: return <AddTemplate />
      case 3: return <ShowTemplates />
      case 4: return <UsersBoard />
      default: return <HomePage />
    }
  }

  function logout() {
    AuthService.logout()
  }

  function who_am_i() {
    AuthService.auto_login()
      .then(r => setUser(r))
  }

  useEffect(()=> {
      toggleOnMobile()
      who_am_i()
    }, [])

  return(
    <Layout className="is-fullheight">

      <Header className="header" style={{backgroundColor: 'white'}}>
        <img
          className="logo-img"
          alt="Logo"
          src={logo}
        />

        <Menu mode="horizontal" style={{float: 'right'}} >
          {/*<Menu.Item key="1">
            <Button block>
              Webinar
            </Button>
          </Menu.Item>
          <Menu.Item key="2">
            <Button block>
              Support
            </Button>
          </Menu.Item>*/}
          <Menu.Item key="3">
            <Button block onClick={()=>logout()} type="primary" className="dark-button">
              Log out
            </Button>
          </Menu.Item>
        </Menu>

      </Header>

      <Layout className="site-layout">

        <Sider collapsible collapsed={collapsed} onCollapse={toggle} >

          <Menu theme="dark" selectedKeys={String(key)} mode="inline" onClick={(key)=>setKey(parseInt(key.key))} defaultSelectedKeys={['1']}>
            {MenuItems.map((item, index)=>(
              <Menu.Item icon={item.icon} key={index+1} >
                {item.title}
              </Menu.Item>
            ))}
            {/*<Menu.Item key="1" icon={<UserOutlined />}>
              Home
            </Menu.Item>
            <Menu.Item key="2" icon={<CloudDownloadOutlined />}>
              Download
            </Menu.Item>
            <Menu.Item key="3" icon={<UploadOutlined />}>
              Training
            </Menu.Item>
            <Menu.Item key="4" icon={<VideoCameraOutlined />}>
              Premium Training
            </Menu.Item>
            <Menu.Item key="5">
              Upsell
            </Menu.Item>
            <Menu.Item key="6">
              WhiteLabel
            </Menu.Item>
            <Menu.Item key="7">
              FAQ
            </Menu.Item>*/}
          </Menu>

        </Sider>

        {component()}

      </Layout>

    </Layout>
  )
}

export default Dashboard