import React, {useEffect, useState} from "react";
import {Row, Col, Card, Table, Switch, Tag, Space, Layout, Form, Input, Typography, Divider, Button, Select} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import {
  DeleteOutlined, EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import UserService from '../Common/_services/user.service'
import SendNotification from "../Common/Utils/SendNotification";

const {Title} = Typography

const columns = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Variables',
    render: (item) => (
      <div>{item.variables.length}</div>
    )
  },
  {
    title: 'Categories',
    render: (item) => (
      <div>{item.categories.length}</div>
    )
  },
  {
    title: 'Marketing Email',
    render: (item => (
      <div>{item.is_marketing ? "Yes" : "No"}</div>
    ))
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: (item) => (
      <div>{item.slice(0,10)}</div>
    )
  },
  {
    title: 'Action',
    key: 'action',
    render: (item) => (
      <Space>
        <Button type="primary" onClick={()=>deleteTemplate(item.id)} >
          Delete
        </Button>
      </Space>
    )
  }
]


const deleteTemplate = (template_id) => {
  UserService.deleteTemplate(template_id)
    .then(r => {
      if(r.message) {
        SendNotification('', 'Template Removed')
      }
    })
}

const ShowTemplates = () => {
  const [addVar, setAddVar] = useState(false)
  const [addCat, setAddCat] = useState(false)
  const [edit, setEdit] = useState(false)
  const [templates, setTemplates] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  function Reload(message) {
    if(message) {
      SendNotification('', message)
    }
    getTemplates()
  }

  const getTemplates = () => {
    SendNotification('', 'Wait a while')
    UserService.showTemplates()
      .then(r => {
        if(r.template) {
          let templates = r.template
          if(templates.length) {
            templates.map((item, index)=>(templates[index]['key'] = templates[index]['id']))
            setTemplates(templates)
          }
          else {
            SendNotification('', 'No Template Added yet')
          }
        }
      })
  }

  const AddVariable = (name, template_id) => {
    setAddVar(false)
    UserService.addVariable(name, template_id)
      .then(r => {
        if(r.id) {
          Reload('Added')
        }
      })
  }

  const AddCategory = (name, template_id) => {
    setAddCat(false)
    UserService.addCategory(name, template_id)
      .then(r => {
        if(r.id) {
          Reload('Added')
        }
      })
  }

  const updateTemplate = (values, template_id) => {
    setEdit(false)
    UserService.updateTemplate(values.name, values.from_email, values.from_name, values.reply_to_email, values.dynamic_id, values.is_marketing, template_id)
      .then(r => {
        if(r.id) {
          Reload('Updated')
        }
      })
  }

  const expandable = { expandedRowRender: item =>
      <div>
        {
          edit ? (
            <Form
              onFinish={(values)=>updateTemplate(values, item.id)}
              initialValues={{
                name: item.name,
                dynamic_id: item.dynamic_template_id,
                is_marketing: item.is_marketing
              }}
            >
              <Form.Item
                name='name'
                label='Template Name'
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='dynamic_id'
                label='Dynamic Id'
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='from_email'
                label='From Email'
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='from_name'
                label='From Name'
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='reply_to_email'
                label='Reply to Email'
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="is_marketing"
                label="Marketing Email"
              >
                <Switch />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                &nbsp;&nbsp;
                <Button onClick={()=>setEdit(false)} >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <p>
              Template Name: {item.name}
              <br />
              Template id: {item.dynamic_template_id}
              <br />
              From Email: {item.from_email}
              <br />
              From Name: {item.from_name}
              <br />
              Reply to: {item.reply_to_email}
              <EditOutlined
                className="inline-block cursor-pointer float-right"
                onClick={()=>setEdit(true)}
              />
            </p>
          )
        }
        {item.variables.length ? (
          <div>
            Variables:&nbsp;&nbsp;{item.variables.map((variable_item, index)=>(
            <div key={variable_item.id}>
              {index+1}.&nbsp;
              {variable_item['name']}&nbsp;
              <DeleteOutlined
                className="cursor-pointer"
                onClick={()=>{
                  deleteVariable(variable_item['id'])
                }}
              />
              <br />
            </div>
          ))}
          </div>
        ) : <p>No Associated Variables</p>}
        {addVar ? (
          <Form
            layout="inline"
            onFinish={values => AddVariable(values.name, item.id)}
          >
            <Form.Item
              name="name"
              rules={[{required: true, message: 'Please Input Variable Name'}]}
            >
              <Input placeholder='Add Variable Name' />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Button onClick={()=>setAddVar(true)}>
            Add new Variable
          </Button>
        )}
        <br />
        <br />
        {item.categories.length ? (
          <div>
            Categories:&nbsp;&nbsp;{item.categories.map((category_item, index)=>(
            <div key={category_item.id}>
              {index+1}.&nbsp;
              {category_item['name']}&nbsp;
              <DeleteOutlined
                className="cursor-pointer"
                onClick={()=>{
                  deleteCategory(category_item['id'])
                }}
              />
              <br />
            </div>
          ))}
          </div>
        ) : <p>No Associated Categories</p>}
        {addCat ? (
          <Form
            layout="inline"
            onFinish={values => AddCategory(values.name, item.id)}
          >
            <Form.Item
              name="name"
              rules={[{required: true, message: 'Please Input Category Name'}]}
            >
              <Input placeholder='Add Category Name' />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Button onClick={()=>setAddCat(true)}>
            Add new Category
          </Button>
        )}
      </div>
  };

  const deleteVariable = (variable_id) => {
    UserService.deleteVariable(variable_id)
      .then(r => {
        if(r.message) {
          Reload('Variable Removed')
        }
      })
  }

  const deleteCategory = (category_id) => {
    UserService.deleteCategory(category_id)
      .then(r => {
        if(r.message) {
          Reload('Category Removed')
        }
      })
  }

  console.log(templates, templates.length ? templates[0]['name'] : '')

  const tableColumns = columns.map(item => ({ ...item }));

  useEffect(()=>getTemplates(), [])

  function onTableRowExpand(expanded, record) {
    let keys = []
    if(expanded) {
      keys.push(record.id)
    }
    setExpandedRowKeys(keys)
  }

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>

        <Col xs={{span: 24}} lg={{span: 20}} >
          <ContentWrapper>

            <Title>
              Show Templates
              <ReloadOutlined
                className="cursor-pointer float-right icon-button"
                onClick={()=>getTemplates()}
              />
            </Title>

            <Divider />

            <Table
              columns={tableColumns}
              dataSource={templates}
              expandable={expandable}
              expandedRowKeys={expandedRowKeys}
              onExpand={onTableRowExpand}
              bordered
            />
              {/*<Column title="name" dataIndex="name" key="name" />
              <Column title="delete" key="action" render={(text)=>(
                <Space size="middle">
                  <a>Delete</a>
                </Space>
              )} />*/}

          </ContentWrapper>
        </Col>

        {/*<Col md={{span: 24}} lg={{span: 12}}>
          <ContentWrapper marginLess>
            <Title style={{fontWeight: 'medium'}}>
              Here's what you can do with ProfileMate!
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            <IFrameComponent url={"https://www.youtube.com/embed/tFNWHLEIiJ0"} />
          </ContentWrapper>
        </Col>*/}

      </Row>
    </PlainContentWrapper>
  )
}

export default ShowTemplates