import React, {useState} from "react";
import {Button, Form, Input} from "antd";
import GetCustomerDetails from "../Common/ApiCall/GetCustomerId";
import {Redirect} from "react-router-dom";
import AuthService from '../Common/_services/auth.service'
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";

const layout = {
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24
  },
};

const SignInForm = ({setTab}) => {
  const [user, setUser] = useState(null)

  const onFinish = values => {
    AuthService.login(values.username, values.password)
      .then(r => {
        console.log(r)
        if(!r.success) {
          throw r.error
        }
        setUser(r.user)
        localStorage.setItem('authToken', r.jwt)
        window.location.reload()
      })
      .catch(e => {
        SendNotification(NotificationTypeEnum.Failure , e)
      })
    /*GetCustomerDetails(values.username, values.password)
      .then(res => setUser(res))
      .then(r => localStorage.setItem('authToken', 'value'))
      .then(r => window.location.reload())
    */
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  if(localStorage.getItem('authToken')) {
    return(
      <Redirect to={{
        pathname: '/',
        state: {user}
      }} />
    )
  }

  return(
    <Form
      {...layout}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input
          placeholder="Username"
          type="text"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password
          placeholder="Password"
        />
      </Form.Item>


      <Form.Item {...tailLayout}>
        <div className="align-center">
          <Button className="dark-button" size="large" type="primary" htmlType="submit">
            LOGIN
          </Button>
          &nbsp;&nbsp;&nbsp;
          {/*<Button size="large" onClick={()=>setTab(0)}>
            SIGN UP
          </Button>*/}
        </div>
      </Form.Item>

{/*      <Form.Item>
        <div className="align-center">
          Forgot Password?
        </div>
      </Form.Item>*/}
      
    </Form>
  )
}

export default SignInForm