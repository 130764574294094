import React, {useEffect, useState} from "react";
import UserService from '../Common/_services/user.service'
import {Row, Col, Table, Typography, Divider, Button} from "antd";
import ContentWrapper from "../Components/ContentWrapper";
import {DeleteOutlined} from "@ant-design/icons";
import SendNotification from "../Common/Utils/SendNotification";

const {Title} = Typography

const ShowUsers = ({goBack}) => {
  const [users, setUsers] = useState([])
  const [blacklist, setBlacklist] = useState([])
  const [show, setShow] = useState(-1)

  function getUsers() {
    UserService.showUsers()
      .then(r => {
        setUsers(r)
        console.log(r)
      })
  }

  function showBlacklist(user_id) {
    UserService.showBlackListMails(user_id)
      .then(r => {
        setShow(user_id)
        console.log(r)
        setBlacklist(r)
      })
  }

  function removeEmail(unsubscribe_id) {
    UserService.deleteBlackListedMail(unsubscribe_id)
      .then(r => {
        showBlacklist(show)
        SendNotification('', 'Email Removed')
      })
  }

  useEffect(()=>getUsers(), [])

  console.log(blacklist)

  if(show===-1) {
    return(
      <ContentWrapper>
        <Title>
          Users
        </Title>
        <div className="float-right">
          <Button onClick={goBack}>
            Go Back
          </Button>
        </div>
        <Divider />
        {users.map((item,index)=>(
          <Title key={index} level={3} >
            {index+1}&nbsp;&nbsp;{item.username}
            <Button className="float-right" type="primary" onClick={()=>showBlacklist(item.id)} >
              See Blacklist
            </Button>
          </Title>
        ))}
      </ContentWrapper>
    )
  } else {
    return (
      <ContentWrapper>
        <Title>
          user
        </Title>
        <div className="float-right">
          <Button onClick={goBack}>
            Go Back
          </Button>
        </div>
        <Divider />
        {blacklist.length ? (
          blacklist.map((item, index)=>(
              <Title key={index} level={3} >
                {index+1}&nbsp;&nbsp;{item.email}
                <DeleteOutlined
                  className="cursor-pointer"
                  onClick={()=>removeEmail(item.id)}
                />
              </Title>
            ))
        ) : (
          <Title level={2} >
            NO BLACKLISTED MAILS
          </Title>
        )}
      </ContentWrapper>
    )
  }
}

export default ShowUsers