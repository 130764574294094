import React from "react";
import {Switch, Route} from "react-router-dom";
import Dashboard from "../Dashboard";
import SignIn from "../Login";
import {PrivateRoute} from "../Components/PrivateRoute";

const Home = () => {

  return(
    <div>
      <Switch>

        <PrivateRoute
          path="/"
          exact
        >
          <Dashboard />
        </PrivateRoute>

        <Route
          path="/sign-in"
          exact
        >
          <SignIn />
        </Route>

      </Switch>
    </div>
  )

}

export default Home