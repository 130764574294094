import React from "react";
import {Route, Redirect} from "react-router-dom";

export const PrivateRoute = ({children, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("authToken") ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/sign-in',
          }}
        />
      )
    }
  />
)
