import {GetRequest, PostRequest} from "../ApiCall/Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError";
import authHeader from "./auth-header";

const DEV_URL = 'http://localhost:5000/mailer/api/v1'

const API_URL = 'https://mailer.profilebud.com/mailer/api/v1'

const GenerateUrl = (uri) => {
  let url = API_URL + uri;
  if(process.env.NODE_ENV === 'development') {
    url = DEV_URL + uri;
  }
  return url
}

const returnResponse = (response) => {
  console.log(response)
  if (response.isValid()) {
    if(response.body.failure) return new ApiResponse({}, 403, response.body.failure)
    return response.body
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${response.error}`;
    return new ApiError(message);
  }
}

class AuthService {

  async login(username, password) {
    const url = GenerateUrl('/login')
    const response = await PostRequest(url, {
      username,
      password
    }, {
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
    //if(response.status !== 200) return response
    //if(response.body.failure) return new ApiResponse({}, 403, response.body.failure)
    return returnResponse(response)
  }

  async register(username, password) {
    const url = GenerateUrl('/users')
    const response = await PostRequest(url, {
        username,
        password
      },
      {
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    console.log(response)
    if(response.status === 406) return new ApiResponse({}, 403, response.error.errors[0])
    if(response.status !== 200) return response;
    if(response.body.failure) return new ApiResponse({}, 403, response.body.failure)
    return response
  }

  logout() {
    localStorage.removeItem("authToken")
    window.location.reload()
  }

  async auto_login() {
    const url = GenerateUrl('/auto_login')
    let user = null
    await GetRequest(url, {} , authHeader())
      .then(resp => {
        console.log("login",resp)
        user = resp.body
      })
    return user
  }

}

export default new AuthService()
