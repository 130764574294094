import {GetRequest, PostRequest} from "../ApiCall/Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError";
import authHeader from "./auth-header";

const DEV_URL = 'http://localhost:5000/mailer/api/v1'

const API_URL = 'https://mailer.profilebud.com/mailer/api/v1'

const GenerateUrl = (uri) => {
  let url = API_URL + uri;
  if(process.env.NODE_ENV === 'development') {
    url = DEV_URL + uri;
  }
  return url
}

const returnResponse = (response) => {
  console.log(response)
  if (response.isValid()) {
    if(response.body.failure) return new ApiResponse({}, 403, response.body.failure)
    return response.body.data
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${response.error}`;
    return new ApiError(message);
  }
}

class UserService {

  async addTemplate(name, is_marketing, variables, categories, dynamic_template_id, from_email, from_name, reply_to_email) {
    const url = GenerateUrl('/add_template');
    const response = await PostRequest(url, {
      name, is_marketing, variables, categories, dynamic_template_id, from_email, from_name, reply_to_email
    }, authHeader() )
    return returnResponse(response)
  }

  async addUser(username, password) {
    const url = GenerateUrl('/add_user')
    const response = await PostRequest(url, {
      username, password
    }, authHeader())
    return returnResponse(response)
  }

  async addVariable(name, template_id) {
    const url = GenerateUrl('/add_variable');
    const response = await PostRequest(url, {
      name, template_id
    }, authHeader())
    return returnResponse(response)
  }

  async addCategory(name, template_id) {
    const url = GenerateUrl('/add_category')
    const response = await PostRequest(url, {
      name, template_id
    }, authHeader())
    return returnResponse(response)
  }

  async updateTemplate(name, from_email, from_name, reply_to_email, dynamic_template_id, is_marketing, template_id) {
    const url = GenerateUrl('/update_template')
    const response = await PostRequest(url, {
      name, dynamic_template_id, is_marketing, template_id, from_email, from_name, reply_to_email
    }, authHeader())
    return returnResponse(response)
  }

  async showTemplates() {
    const url = GenerateUrl('/show_templates')
    const response = await GetRequest(url, {}, authHeader())
    return returnResponse(response)
  }

  async showUsers() {
    const url = GenerateUrl('/show_users')
    const response = await GetRequest(url, {}, authHeader())
    return returnResponse(response)
  }

  async showBlackListMails(user_id) {
    const url = GenerateUrl('/show_user')
    const response = await GetRequest(url, {user_id}, authHeader())
    return returnResponse(response)
  }

  async deleteTemplate(template_id) {
    const url = GenerateUrl('/delete_template')
    const response = await GetRequest(url, {
      template_id
    }, authHeader())
    return returnResponse(response)
  }

  async deleteVariable(variable_id) {
    const url = GenerateUrl('/delete_variable')
    const response = await GetRequest(url, {
      variable_id
    }, authHeader())
    return returnResponse(response)
  }

  async deleteCategory(category_id) {
    const url = GenerateUrl('/delete_category')
    const response = await GetRequest(url, {
      category_id
    }, authHeader())
    return returnResponse(response)
  }

  async deleteBlackListedMail(unsubscribe_id) {
    const url = GenerateUrl('/delete_blacklist')
    const response = await GetRequest(url, {
      unsubscribe_id
    }, authHeader())
    return returnResponse(response)
  }

}

export default new UserService()